.App-amazon-link {
    margin-top: 20px !important;
}

.amazon-link {
    font-size: 14px;
    text-align: left;
}

.App-amazon-separator {
    width: 90%;
    margin: 20px auto;
    height: 1px;
    background: rgba(0,0,0,0.5);
}

.line-results-amazon {
    padding: 10px;
}

.title-link {
    font-size: 18px;
}

.amazon-link h5, .amazon-link h4 {
    text-align: center;
}

.amazon-link h5 {
    text-transform: capitalize;
    font-weight: bold;
}

.amazon-link h4 {
    width: 100%;
    background: #1DACCF;
    display: block;
    padding: 10px 0;
    color: #fff;
    margin-top: 20px;
}

@media (max-width: 800px) {
    .App-amazon-link button {
        font-size: 12px;
    }

    .title-link {
        font-size: 16px;
    }
}
