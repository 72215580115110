.App-repertory {
    margin-top: 20px;
}

.App-repertory .col-sm-6 {
    position: relative;
    margin-bottom: 20px;
}

.App-repertory .col {
    position: relative;
    margin-bottom: 20px;
    min-height: 300px;
}

.App-repertory .col-sm-6 a, .App-repertory .col a {
    text-decoration: none;
    color: #000;
}

.App-repertory .col-sm-6 a b, .App-repertory .col a b {
    color: white;
    background: #1DACCF;
    width: 100%;
    display: block;
    padding: 2px 0 5px 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    font-size: 21px;
}

.App-repertory-desc {
    margin: 10px 0;
}

.App-repertory-desc.App-diagram {
    background: #ffc108;
    margin: 0 0 10px 0;
    color: #ffffff;
    padding: 10px 0;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-repertory-hardcopy {
    margin-bottom: 30px;
}

.App-repertory-wrapper {
    position: relative;
}

.App-repertory-wrapper, .App-repertory-wrapper-diagram {
    margin: 5px;
    box-shadow: 0 14px 24px -16px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    height: 609px;
}

.App-repertory-wrapper:hover, .App-repertory-wrapper-diagram:hover {
    box-shadow: 0 14px 34px -10px rgba(0, 0, 0, 0.5);
}

.App-repertory-wrapper-diagram {
    height: auto;
    min-height: 300px;
    position: relative;
}

.App-repertory-iframe {
    position: absolute;
    width: calc(100% - 60px);
    height: 450px;
    top: 144px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.App-repertory-iframe-wrapper {
    position: absolute;
    width: 340px;
    height: 450px;
    border-radius: 5px;
    top: 114px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.App-repertory-iframe-wrapper.loading {
    background: rgba(0,0,0,0.2);
}

.App-repertory-iframe-wrapper:hover {
    background: rgba(0,0,0,0.2);
}

/* Loading Img Diagram */
.App-repertory-iframe-wrapper-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    background: #E8B951;
    opacity: 0;
    transition: opacity 1s ease-in;
}

.App-repertory-iframe-wrapper-loading.loading {
    opacity: 1;
}

.App-repertory-iframe-wrapper-loading img {
    width: 100px;
    position: absolute;
    left: 50%;
    top: calc(50% - 60px);
    transform: translate(-50%);
}

.App-repertory-iframe-wrapper-loading span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    color: #ffffff;
    background: #E8B951;
}

@media (max-width: 800px) {
    .App-repertory-wrapper {
        /*position: relative;*/
    }

    .App-repertory-wrapper-diagram {
        min-height: 268px;
        position: relative;
    }

    .App-repertory .col-sm-6 b, .App-repertory .col b {
        font-size: 16px !important;
        padding: 10px 0 !important;
    }

    .App-repertory-iframe {
        width: 100%;
        height: 450px;
        top: 74px;
        transform: translateX(-50%) scale(0.8);
    }

    .App-repertory-wrapper, .App-repertory-wrapper-diagram {
        height: 495px;
    }
}

